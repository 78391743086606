import { defineStore } from 'pinia';
import { getClient } from '~/util/awclient';
export const useServerStore = defineStore('server', {
    state: () => ({
        info: null,
    }),
    actions: {
        async getInfo() {
            try {
                const info = await getClient().getInfo();
                this.$patch({ info: info });
            }
            catch (e) {
                console.error('Unable to connect: ', e);
            }
        },
    },
});
