import { defineStore } from 'pinia';
import moment from 'moment';
import { getClient } from '~/util/awclient';
import { defaultCategories, cleanCategory } from '~/util/classes';
import { defaultViews } from '~/stores/views';
import { isEqual } from 'lodash';
function jsonEq(a, b) {
    const jsonA = JSON.parse(JSON.stringify(a));
    const jsonB = JSON.parse(JSON.stringify(b));
    return isEqual(jsonA, jsonB);
}
// Backoffs for NewReleaseNotification
export const SHORT_BACKOFF_PERIOD = 24 * 60 * 60;
export const LONG_BACKOFF_PERIOD = 5 * 24 * 60 * 60;
// Initial wait period for UserSatisfactionPoll
export const INITIAL_WAIT_PERIOD = 7 * 24 * 60 * 60;
export const useSettingsStore = defineStore('settings', {
    state: () => ({
        initialTimestamp: moment(),
        startOfDay: '04:00',
        startOfWeek: 'Monday',
        durationDefault: 4 * 60 * 60,
        useColorFallback: false,
        landingpage: '/home',
        theme: 'light',
        newReleaseCheckData: {
            isEnabled: true,
            nextCheckTime: moment().add(SHORT_BACKOFF_PERIOD, 'seconds'),
            howOftenToCheck: SHORT_BACKOFF_PERIOD,
            timesChecked: 0,
        },
        userSatisfactionPollData: {
            isEnabled: true,
            nextPollTime: moment().add(INITIAL_WAIT_PERIOD, 'seconds'),
            timesPollIsShown: 0,
        },
        always_active_pattern: '',
        classes: defaultCategories,
        views: defaultViews,
        // Developer settings
        // NOTE: PRODUCTION might be undefined (in tests, for example)
        devmode: typeof PRODUCTION === 'undefined' ? true : !PRODUCTION,
        showYearly: false,
        useMultidevice: false,
        requestTimeout: 30,
        _loaded: false,
    }),
    getters: {
        loaded(state) {
            return state._loaded;
        },
    },
    actions: {
        async ensureLoaded() {
            if (!this.loaded) {
                await this.load();
            }
        },
        async load({ save } = {}) {
            if (typeof localStorage === 'undefined') {
                console.error('localStorage is not supported');
                return;
            }
            const client = getClient();
            // Fetch from server, fall back to localStorage
            const server_settings = await client.get_settings();
            const all_keys = [...Object.keys(localStorage), ...Object.keys(server_settings)].filter(key => {
                // Skip keys starting with underscore, as they are local to the vuex store.
                return !key.startsWith('_');
            });
            const storage = {};
            for (const key of all_keys) {
                // If key is set in server, use that value, otherwise use localStorage
                const set_in_server = server_settings[key] !== undefined;
                let value = set_in_server ? server_settings[key] : localStorage.getItem(key);
                //const locstr = set_in_server ? '[server]' : '[localStorage]';
                //console.debug(`${locstr} ${key}:`, value);
                // Keys ending with 'Data' are JSON-serialized objects in localStorage
                if ((key.endsWith('Data') || key == 'views' || key == 'classes') && !set_in_server) {
                    try {
                        value = JSON.parse(value);
                        // Needed due to https://github.com/ActivityWatch/activitywatch/issues/1067
                        if (key == 'classes') {
                            value = value.map(cleanCategory);
                        }
                        storage[key] = value;
                    }
                    catch (e) {
                        console.error('failed to parse', key, value, e);
                    }
                }
                else if (value === 'true' || value === 'false') {
                    storage[key] = value === 'true';
                }
                else {
                    storage[key] = value;
                }
            }
            this.$patch({ ...storage, _loaded: true });
            // Since `requestTimeout` is used to initialize the client, we need to set it again
            // https://github.com/ActivityWatch/activitywatch/issues/979
            client.req.defaults.timeout = this.requestTimeout * 1000;
            if (save) {
                await this.save();
            }
        },
        async save() {
            // Important check, to avoid saving settings before they are loaded (potentially overwriting them with defaults)
            if (!this.loaded) {
                console.error('Settings not loaded, not saving');
                return;
            }
            // We want to avoid saving to localStorage to not accidentally mess up pre-migration data
            // For example, if the user is using several browsers, and opened in their non-main browser on first run after upgrade.
            const saveToLocalStorage = false;
            // Save to localStorage and backend
            // NOTE: localStorage deprecated, will be removed in future
            const client = getClient();
            // Fetch current settings from server
            const server_settings = await client.get_settings();
            // Save settings
            for (const key of Object.keys(this.$state)) {
                // Skip keys starting with underscore, as they are local to the vuex store.
                if (key.startsWith('_')) {
                    continue;
                }
                const value = this.$state[key];
                // Save to localStorage
                // NOTE: we always save the theme and landingpage to localStorage, since they are used before the settings are loaded
                if (saveToLocalStorage || key == 'theme' || key == 'landingpage') {
                    if (typeof value === 'object') {
                        localStorage.setItem(key, JSON.stringify(value));
                    }
                    else {
                        localStorage.setItem(key, value);
                    }
                }
                // Save changed settings to backend
                if (server_settings[key] === undefined || !jsonEq(server_settings[key], value)) {
                    if (server_settings[key] === undefined && value === false) {
                        // Skip saving settings that are set to false and not already saved on the server
                        continue;
                    }
                    console.log('Saving', { [key]: value });
                    //console.log('Was:', server_settings[key]);
                    //console.log('Now:', value);
                    await client.req.post('/0/settings/' + key, value, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                }
            }
            // After save, reload
            await this.load({ save: false });
        },
        async update(new_state) {
            console.log('Updating state', new_state);
            this.$patch(new_state);
            await this.save();
        },
    },
});
